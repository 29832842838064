.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
}

h1 {
  margin-bottom: 0;
}

.main-container-wrapper {
  height: 100vh;
  overflow: auto;
  padding: 0 5px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.date-picker-label {
  color: #ffa200;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.main-container-wrapper ::-webkit-scrollbar {
  width: 7px;
  margin: 20px;
  padding: 30px;
  border-radius: 25px;
}
.main-container-wrapper::-webkit-scrollbar-thumb {
  border-radius: 25px;
  width: 2px;
  background: #515050;
}

.main-container-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
  width: 3px;
}
.main-container-wrapper::-webkit-scrollbar {
  width: 7px;
  margin: 20px;
  padding: 30px;
  border-radius: 25px;
}
