.container-linerbg {
  /* background: linear-gradient(to right bottom, #e6ddef 65%, #602a94); */
  min-height: 100vh;
}
.primary-text {
  color: #515050;
}
.secondary-text {
  color: #602a94;
}
.primary-icon {
  color: #602a94;
}
.primary-background {
  background-color: #602a94;
}
.primary-border {
  border: 2px solid #602a94;
}

@media screen and (max-width: 768px) {
  .primary-border {
    margin-bottom: 20px;
  }
}
.secondary-border {
  border: 2px solid #ffab18;
}
.primary-btn {
  background-color: #602a94;
  color: white;
  font-size: 0.8rem;
}
.secondary-btn {
  background-color: #ffab18;
  color: white;
  font-size: 0.8rem;
}
/* .primary-btn:hover {
  background-color: #ffab18;
  color: white;
  transition: 0.2s;
} */
.common-template-card {
  height: 25rem;
  object-fit: contain;
  box-sizing: content-box;
}

.form-input label {
  color: black;
  margin-bottom: 5px;
}
.form-input input {
  border: 1.5px solid #602a94;
  outline: #602a94;
  border-radius: 7px;
  color: rgb(57, 57, 57);
  font-weight: 500;
  padding: 10px;
  font-size: 1rem;
  outline: #602a94;
}
.form-input input:disabled {
  color: #838282;
  border: 1.5px solid gray;
}
.form_input_required {
  border: 1.5px solid red;
}

.form-input input:focus {
  border: 1.5px solid #602a94;
}
.form-input select:focus {
  border: 1.5px solid #602a94;
}
.form-input textarea {
  border: 1.5px solid #602a94;
  outline: #602a94;
  border-radius: 7px;
  color: rgb(74, 74, 74);
  padding: 10px;
  font-size: 1rem;
  background-color: white;
}
.form-input input::placeholder {
  color: rgb(182, 180, 180);
}

.form-input select {
  border: 1.5px solid #602a94;
  outline: #602a94;
  border-radius: 7px;
  color: rgb(74, 74, 74);
  padding: 10px;
  font-size: 1rem;
}

/* ---------->steps Wrapper------------> */
.resume-step-preview-wrapper {
  overflow-y: auto;
}
.resume-step-content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 15px;
}
.resume-steps-wrapper {
  position: relative;
  overflow: hidden;
  height: 100vh;
}
.resume-steps-wrapper::before {
  content: "";
  position: absolute;
  right: -25%;
  bottom: -60%;
  /* background: radial-gradient(40% 40% at 50% 50%, #ffab18 -70%, #fff 120%); */
  height: 850px;
  width: 800px;
  border-radius: 50%;
  display: block;
  z-index: -1;
}
.resume-step-content {
  /* overflow-x: auto;
  overflow-y: auto; */
  /* height: 60vh; */
}

/* 
.resume-steps-wrapper::after {
  content: "";
  position: absolute;
  top: -100%;
  z-index: -1;
  right: 25%;
  background: radial-gradient(40% 40% at 50% 50%, #602a94 -70%, #fff 120%);
  display: block;
  height: 850px;
  width: 800px;
  } */

.resume-content-footer-btn {
  padding-bottom: 1rem;
}
.back-btn-container {
  height: 10vh;
}

.accordion-button:not(.collapsed) {
  background-color: #f0ddff;
  color: #515050;
  outline: none;
}
.accordion-button {
  color: #515050;
  font-weight: 600;
}
.accordion-button::after {
  color: #515050;
}
.accordion-button:focus {
  box-shadow: none;
}

/* Custom CSS to change accordion arrow color to #602a94 */
.accordion-button:not(.collapsed)::after {
  /* Arrow color when accordion is expanded */
  filter: invert(19%) sepia(82%) saturate(3621%) hue-rotate(250deg)
    brightness(87%) contrast(99%);
}

.accordion-button.collapsed::after {
  /* Arrow color when accordion is collapsed */
  filter: invert(19%) sepia(82%) saturate(3621%) hue-rotate(250deg)
    brightness(87%) contrast(99%);
}

.skill-container {
  min-height: 48vh;
}

.skill-addBtn {
  border: 2px solid #602a94;
  color: #602a94;
  outline: none;
}
.skill-addBtn-active {
  background: #602a94;
  color: white;
  outline: none;
}
.skill-addBtn:disabled svg {
  color: #602a94;
}
.skill-addBtn svg {
  color: #602a94;
}

.skill-addBtn:hover {
  /* background-color: #602a94; */
  /* color: white; */
}

.skill-addBtn-active svg {
  color: white;
}
/* scrollbar */

/* Track */
.container-linerbg::-webkit-scrollbar {
  width: 7px;
  margin: 20px;
  padding: 30px;
  border-radius: 25px;
}
.container-linerbg::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
  width: 3px;
}
.resume-step-preview-wrapper::-webkit-scrollbar {
  width: 7px;
  margin: 20px;
  padding: 30px;
  border-radius: 25px;
}
.resume-step-preview-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
  width: 3px;
}

/* Handle */
.resume-step-preview-wrapper::-webkit-scrollbar-thumb {
  border-radius: 25px;
  width: 2px;
  background: #515050;
}

/* Handle on hover */
.resume-step-preview-wrapper::-webkit-scrollbar-thumb:hover {
  background: #ffab18;
}

.sidebar-open-btn {
  position: absolute;
  right: -13%;
  top: 50%;
  background: #f0ddff;
  z-index: 1000;
  color: #602a94;
  border-radius: 0 5px 5px 0;
}

.preview-card {
  width: 100%;
  height: 470px;
}
.preview {
  position: sticky;
  top: 0%;
}
/* contact */
.contact-picture {
  height: 90px;
  width: 100px;
}
.search-Suggestion {
  position: absolute;
  z-index: 100;
}

.Ai-Overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(28, 27, 27, 0.1);
  top: 0;
  right: 0;
}

.Ai-Overlay button {
  opacity: 0.8;
}

.cmn-gray {
  color: #515050;
}

.cmn-gray-bg {
  background: #515050;
  font-size: 0.8rem;
  color: white;
}

.changeTemplate-preview {
  background-color: #f0ddff;
}

.change-template {
}
.change-template-left {
  height: 85vh;
}

.change-template-alltemplates {
  height: 75vh;
  overflow: scroll;
  overflow-x: auto;
}
/* General styles for 
the modal */

.change-template-alltemplates::-webkit-scrollbar {
  width: 7px;
  margin: 20px;
  padding: 30px;
  border-radius: 25px;
}
.change-template-alltemplates::-webkit-scrollbar-thumb {
  border-radius: 25px;
  width: 2px;
  background: #515050;
}

.change-template-alltemplates::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
  width: 3px;
}
.change-template-alltemplates::-webkit-scrollbar {
  width: 7px;
  margin: 20px;
  padding: 30px;
  border-radius: 25px;
}

.modal-closing-btn {
  position: absolute;
  right: -1%;
  top: -5%;
  background: #602a94;
  color: white;
  border-radius: 50%;
  /* padding: 10px; */
  height: 50px;
  width: 50px;
}
/* Responsive adjustments */
@media (max-width: 1024px) {
  .sidebar-open-btn {
    /* display: none; */
  }
}
/* Default styles for desktop */
.flex-row-responsive {
  display: flex;
  flex-direction: row;
  gap: 50px; /* Adjust the gap as needed */
  width: 100%; /* Full width */
}

.flex-column-responsive {
  display: flex;
  flex-direction: row;
  gap: 20px; /* Adjust the gap as needed */
  width: 100%; /* Full width */
}

/* Media queries for mobile devices */
@media (max-width: 768px) {
  .flex-row-responsive {
    flex-direction: column; /* Stack elements vertically on small screens */
    gap: 0px;
  }
  .flex-column-responsive {
    flex-direction: column;
    gap: 15px;
  }
}

@media screen and (max-width: 768px) {
  .preview {
    display: none;
  }
}
@media (max-width: 768px) {
  .primary-border {
    flex-direction: column;
    align-items: center; /* Center align items when stacked vertically */
  }

  .primary-border > .d-flex {
    width: 100%; /* Full width to use available space */
    justify-content: space-between; /* Center buttons within each block */
    margin-bottom: 0.5rem; /* Add some space between the button groups */
  }

  .primary-border .align-self-end {
    align-self: center; /* Adjust alignment to center for mobile */
  }
}

/* Statistics.css */
.statistics-container {
  display: flex;
  flex-direction: row; /* Align items in a row by default */
  justify-content: center; /* Center the items horizontally */
  gap: 20px; /* Spacing between items */
  margin: 20px 0;
}

.resume-common-card {
  width: 275px;
  height: 414px !important;
  object-fit: cover;
  overflow: hidden;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.resume-common-card ::-webkit-scrollbar {
  width: 7px;
  margin: 20px;
  padding: 30px;
  border-radius: 25px;
}
.resume-common-card::-webkit-scrollbar-thumb {
  border-radius: 25px;
  width: 2px;
  background: #515050;
}

.resume-common-card::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
  width: 3px;
}
.resume-common-card::-webkit-scrollbar {
  width: 7px;
  margin: 20px;
  padding: 30px;
  border-radius: 25px;
}
.review-section-with-card {
  background-color: #f0ddff;
}

@media (max-width: 768px) {
  /* Adjusts for tablets and below */
  .statistics-container {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items when stacked */
    gap: 10px; /* Smaller gap for smaller screens */
  }
  .resume-common-card {
    width: 100%;
  }
}

.resume-card-responsive-selected {
  border: 3px solid #602a94;
}

.resume-card-responsive-selected-icon {
  position: absolute;
  right: -5%;
  top: -4%;
  background: #602a94;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  /* font-size: 40; */
  z-index: 100;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-for-coverLetter {
  background: #602a94;
  color: white;
}

.coverLetter-card-Wrapper {
  position: sticky;
  top: 30%;
  margin-top: 20px;
}
.preview-btn .mobile-only-icon {
  display: none; /* Hide by default */
}

@media (max-width: 768px) {
  /* Adjust breakpoint as needed */
  .preview-btn .mobile-only-icon {
    display: block; /* Show only on mobile */
    position: fixed;
    right: 15px;
    top: 98px;
  }
}

/* Customizing the toast container */
.toaster {
  background-color: #333 !important; /* Dark background for the toast container */
}

/* Customizing individual toasts */
.toast {
  background-color: #4a90e2; /* Custom primary color */
  color: #fff; /* White text color */
  border-radius: 8px; /* Rounded borders */
  padding: 16px; /* Padding around text */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 10px rgba(96, 42, 148, 0.5); /* Initial shadow */
  }
  50% {
    box-shadow: 0 0 20px rgba(96, 42, 148, 0.8); /* Stronger shadow */
  }
  100% {
    box-shadow: 0 0 10px rgba(96, 42, 148, 0.5); /* Back to initial shadow */
  }
}

.ai-description-box {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  animation: shadowPulse 2s infinite; /* Apply the shadow animation */
}
