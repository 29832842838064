.review-page-body-container-one {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: #f7f3ff;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 8px rgba(61, 61, 61, 0.062);
}

.review-selected-template-image-container {
  width: 22%;
  border-radius: 7px;
  overflow: hidden;
  border: 0.2px solid black;
}

.review-selected-template-image-container img {
  width: 100%;
}

.resume-cover-template-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
}

.review-info-container {
}

.review-info-container h4 {
  /* font-size: 32px;
  color: #6750a4;
  font-weight: 600; */
}

.review-info-container h4 span {
  display: block;
  color: #ffa303;
}

.review-info-container p {
  /* font-size: 15px;
  font-weight: 600; */
}

.review-info-container button {
  padding: 10px 22px;
  border: none;
  border-radius: 6px;
  background-color: #521986;
  color: white;
}

.review-analytics-card-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px;
  gap: 20px;
}

.review-analytics-card {
  padding: 15px;
  background-color: white;
  width: 110px;
  height: 155px;
  box-shadow: 0px 0px 5px 5px rgba(74, 74, 74, 0.088);
  border-radius: 8px;
  margin: 5px 0;
}

.review-analytics-card img {
  width: 100%;
}

.review-analytics-card p {
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  margin: 10px 0;
}

.review-page-header-container {
  padding: 0 15px;
}

.review-page-header-container h3 {
  color: #6750a4;
}

.review-page-header-container p {
  font-weight: 600;
}

.practice-videos-buttonTab {
  /* padding: 0 15px; */
}

@media (max-width: 568px) {
  .review-selected-template-container {
    overflow: auto;
    width: 100%;
  }
}
