.practice-videos-wrapper {
  padding: 10px;
}

/* 
.practice-videos-wrapper h3 {

    color: #681caf;
    font-size: 29px;

} */

/* 
.practice-videos-wrapper p {
    margin: 15px 0;
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.716);

} */

.practiceVideos-videos-container {
  display: grid;
  gap: 20px;
  align-items: center;
  grid-template-columns: fit-content(100%) fit-content(100%) fit-content(100%);
  margin: 15px auto;
}

.practiceVideos-video-preview-card {
  -webkit-box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.089);
  border-radius: 10px;
  width: 320px;
  padding: 10px 15px;
  box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.089);
  margin: 0px 0;
  transition: all 0.3s;
  display: grid;
}

/* 
.practiceVideos-video-preview-card:hover {
    cursor: pointer;
    transform: scale(1.03);
} */

.practiceVideos-video-preview-card img {
  width: 100%;
  margin: 10px 0;
  object-fit: contain;
  border-radius: 7px;
}

.practiceVideos-video-preview-card h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0;
  margin-bottom: 15px;
}

.practiceVideos-video-preview-card p {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
}

.practiceVideos-video-preview-card span {
  font-size: 12px;
  font-weight: 700;
  color: rgba(255, 175, 25, 0.885);
}

.practiceVideos-video-preview-card-button-container {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.practiceVideos-video-preview-card-button-container button {
  width: 83%;
  padding: 10px 5px;
  border: none;
  background-color: #521986;
  color: white;
  border-radius: 7px;
}

.practiceVideos-video-preview-card-button-container span {
  width: 15%;
  padding: 10px;
  border: 0.23px solid rgba(255, 175, 25, 0.885);
  border-radius: 7px;
  color: rgba(255, 175, 25, 0.885);
  display: flex;
  justify-content: center;
}

.span-active {
  background: rgba(255, 175, 25, 0.885);
}

.span-active svg {
  color: white;
}

.practice-videos-buttonTab {
  display: flex;
  margin: 25px 0;
}

.practice-videos-buttonTab button {
  margin-right: 20px;
  border: none;
  padding: 1rem;
  background-color: rgba(236, 193, 255, 0.255);
  color: #521986;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  border-radius: 6px;
  transition: all 0.2s;
}

.practice-videos-buttonTab button:hover {
  background-color: #521986;
  color: rgb(250, 245, 253);
}

.practice-videos-buttonTab button svg {
  position: relative;
  right: 5px;
  bottom: 1px;
}

.selected-course-recommendations-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.selected-course-container {
  width: 80%;
  position: relative;
}

.selected-course-container button {
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
}

.selected-course-container button svg {
  width: 23px;
  height: 25px;
  color: #521986;
}

.selected-course-container img {
  width: 100%;
  border-radius: 7px;
  border: 0.2px solid #681cafc8;
  margin: 15px 0;
  margin-bottom: 35px;
}

.selected-course-container h3 {
  color: #681caf;
  font-size: 24px;
  font-weight: 700;
  margin-left: 35px;
}

.selected-course-container p {
  font-size: 14px;
  font-weight: 600;
}

.recommendations-container {
  width: 35%;
  padding: 10px;
  margin-left: 20px;
}

.recommended-videos-container {
  height: 550px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 0 10px;
}

.recommended-videos-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.recommended-videos-container::-webkit-scrollbar-thumb {
  background-color: #ffba60;
}

.recommended-videos-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.recommendations-container-header {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 5px;
}

.recommendations-container-header p {
  font-weight: 600;
  color: #681caf;
}

.recommendations-container-header span {
  color: #681caf;
}

.bestpractice-back {
  color: #521986;
  font-weight: 700;
}

@media (max-width: 768px) {
  .selected-course-recommendations-container {
    flex-direction: column;
  }

  .recommendations-container {
    margin: 0;
    width: 100%;
  }

  .practiceVideos-video-preview-card {
    width: 280px;
    margin-bottom: 20px;
  }
}
