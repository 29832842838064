.common-dashbooard-card {
  width: 275px;
  position: relative;
  height: 417px;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card-for-common {
  display: flex;
  justify-content: center;
  align-items: start;
  overflow: hidden;
  height: 550px !important;
  /* height: 100%; */
}

.card-for-common svg {
  color: #521986;
}

.common-dashbooard-card-footer {
  align-items: center;
}

.common-dashbooard-card-footer span {
  background-color: rgb(36, 191, 36);
  padding: 4px 15px;
  color: white;
}

.card-for-common-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  transition: 0.2s;
  opacity: 0;
}

.common-dashbooard-card:hover {
  transition: 0.2s;
  transform: scale(1.02);
}

@media screen and (max-width: 768px) {
  .common-dashbooard-card {
    width: 100%;
  }
}
/* 
.common-dashbooard-card:hover::before {
  content: "ok";
  height: 100%;
  width: 100%;
  position: absolute;
  transition: 1s;
  background-color: rgb(137, 132, 132);
} */
