.date-picker-container {
  position: relative;
  display: inline-block;
}

.date-picker-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.date-picker-calendar {
  position: absolute;
  z-index: 1000;
  top: 45px; /* Adjust this value based on your input's height */
  left: 0;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.calendar-hidden {
  display: none;
}

.react-calendar__month-view__months__month {
  max-width: 60px; /* Adjust as necessary to fit 3 letters */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-calendar__navigation__label__labelText {
  max-width: 60px; /* Adjust as necessary to fit 3 letters */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-calendar__tile {
  max-width: 33%;
  overflow: hidden;
  width: 33%;
  padding: 0;
  margin-inline-end: 0px;
  background: none;
  flex-basis: auto !important;
  transition: none;
  margin: 2px;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__navigation {
  background-color: #ffab18;
  /* border-bottom: 1px solid #602a94; */
}
.react-calendar__navigation__label__labelText {
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
}
.react-calendar {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  /* border: 1px solid #602a94; */
}
.react-calendar__tile abbr {
  color: #602a94;
  font-weight: 500;
  font-size: 0.8rem;
}
