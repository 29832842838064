.nav-wrapper {
  min-height: 100vh;
  background-color: #f0ddff;
  transition: 0.05s ease;
}

/* @media screen and (max-width: 1024px) {
  .nav-wrapper {
    display: none;
  }
} */
.resume-steps-nav {
  position: relative;
  background-color: #f0ddff;
  padding: 20px;
}

.Common-sidebar-circles {
  background: white;
  width: 40px;
  font-weight: 700;
  height: 40px;
  position: relative;
  border-radius: 50%;
  color: #602a94;
}
.Common-sidebar-circles-active {
  background: #602a94;
  color: white !important;
  width: 42px;
  font-weight: 700;
  height: 42px;
  position: relative;
  border-radius: 50%;
  color: #602a94;
}
.logo-container img {
  width: 60%;
  height: 60%;
  margin-left: 10px;
}
.logo-container {
  color: #602a94;
}
.Common-sidebar-line {
  position: absolute;
  top: 100%;
  height: 3rem;
  display: inline-block;
  width: 3px;
  background-color: white;
  font-weight: 600;
}

.Common-sidebar-progress {
  cursor: pointer;
}
/* .Common-sidebar-circles::before {
  content: "";
  position: absolute;
  font-weight: bold;
  height: 10px;
  width: 10px;
  background-color: white;
} */

/* usersidebar.css */
.sidebar-open-btn {
  color: #602a94;
  /* border: 2px solid #602a94; */
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.activeStep {
  background: #602a94;
  color: white;
  border-color: #602a94;
  transition: 0.3s;
  transform: scale(1.2);
}
.activeStepName {
  color: #515050;
}

@media screen and (max-width: 768px) {
  .desktopview {
    display: none;
  }
  /* .nav-wrapper {
    display: none;
  } */
  .resume-card-responsive {
    width: 100%;
  }
  .common-dashbooard-card {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .desktopview {
    display: none;
  }
}
