@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Lato:wght@300;400;700;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

svg {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

.main-content {
  top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-select:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}
.showLoader {
  display: none;
}

@media all and (max-width: 700px) {
  .showLoader {
    display: block;
  }
  .notShowLoader {
    display: none;
  }
}
