.dropdown-item {
  padding: 10px !important;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-item:hover,
.dropdown-item.active {
  background-color: #e0e0e0;
  color: #515050;
}
.sidebar-container {
  display: none;
}

@media screen and (max-width: 1024px) {
  .sidebar-container {
    width: 100%;
    background-color: #6a0dad;
    color: white;
    position: relative;
    font-size: 16px;
    border-radius: 8px;
    overflow: hidden;
    z-index: 1;
    display: block;
  }
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}

.sidebar-body {
  background-color: #5c0099;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px;
  transition: background-color 0.2s ease;
}

.icon {
  margin-right: 10px;
}
