.step-progress-main {
  border: 0.5px solid #ffa303;
  border-radius: 10px;
  height: 110px;
  padding: 20px;
}

.steps-progress-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.steps-progress-bar .step-circle,
.steps-progress-bar .step-circle-orange {
  width: 30px;
  height: 30px;
  font-weight: 500;
  background: #f7f3ff;
  line-height: 15px;
  color: #381e72;
  border-radius: 50%;
  display: flex;
  align-items: center;
  place-content: center;
  position: relative;
  border: 0.5px solid #381e72;
  transition: 0.6s all ease-in;
}

.steps-progress-bar span:hover,
.steps-progress-bar h4:hover {
  cursor: pointer;
}

.steps-progress-bar .step-circle h4,
.steps-progress-bar .step-circle-orange h4 {
  background: #f7f3ff;
  position: absolute;
  top: 40px;
  font-size: 12px;
  white-space: nowrap;
  border: 0.5px solid #381e72;
  border-radius: 4px;
  padding: 5px;
  transition: 0.6s all ease-in;
}

.steps-progress-bar .step-circle-orange,
.steps-progress-bar .step-circle-orange h4 {
  background: #ffa303;
  border: 0.5px solid #ffa303;
  color: #fff;
}

.steps-progress-bar .step-line {
  width: 95%;
  height: 5px;
  background: #f7f3ff;
  position: absolute;
  top: 40%;
  z-index: -2;
  left: 30px;
  margin: auto;
  color: transparent;
  transition: 0.6s all ease-in;
}

.steps-progress-bar .step-line-orange {
  height: 5px;
  background: #ffa303;
  position: absolute;
  top: 40%;
  z-index: -1;
  left: 30px;
  margin: auto;
  color: transparent;
  transition: 0.6s all ease-in;
}

.step-progres-button {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}

.step-progres-button button {
  width: 100px;
  height: 45px;
  background: #521986;
  border-radius: 10px;
  color: #fff;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
}

.step-progres-button button:hover {
  background: #8822e7;
}

.step-progres-button button:disabled {
  background: #943ee3;
  cursor: not-allowed;
}

.preview-card {
  background: #ffffff;
  border-radius: 10px;
  overflow-y: auto;
  height: 100%;
  width: 275px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 414px;
}

.preview-card img {
  border-radius: 10px;
  height: 100%;
}

.preview-card img:hover {
  cursor: pointer;
}

/* 
.preview-card div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.preview-card div h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #521986;
  display: flex;
  /* place-content: center; */
  align-items: center;
}

.preview-card div button {
  width: 82px;
  height: 39px;
  background: #521986;
  border-radius: 10px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin: 1em 0;
}

.preview-main .second-item {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #381e72;
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #ffa303;
  list-style: none;
  padding: 0 10px 10px 0;
  height: 155px;
  overflow: auto;
  overflow-x: hidden;
}

.preview-main .second-item::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.preview-main .second-item::-webkit-scrollbar-thumb {
  background-color: #ffba60;
}

.preview-main .second-item::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.preview-main .second-item li {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-bottom: 1px solid #ffa303;
  padding: 15px 10px 15px 20px;
  display: flex;
  justify-content: space-between;
}

.preview-main .second-item li span {
  display: flex;
}

.preview-main .second-item li span img {
  margin: 3px 10px 0px;
  height: 15px;
  width: 15px;
}

.preview-main .second-item li:last-child {
  border: none;
}

.create-resume-main h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  /* line-height: 34px; */
  font-family: "Inter", sans-serif;
  color: #000000;
}

.create-resume-main p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  line-height: 19px;
  color: black;
}

.create-resume-main .form-main {
  background: #f6eeff;
  border: 0.5px solid #ffa303;
  border-radius: 10px;
  padding: 12px;
  width: 55vw;
}

.create-resume-main-job .form-main {
  background: #f6eeff;
  border: 0.5px solid #ffa303;
  border-radius: 10px;
  padding: 12px;
}

.create-resume-main .form-main .profile-img-main {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}

.create-resume-main .form-main .profile-img {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.create-resume-main .form-main .profile-img h5 {
  padding: 12px 8px;
  background: #f7f3ff;
  border-radius: 8px;
  color: #521986;
  font-weight: 600;
  font-size: 14px;
  width: 120px;
  margin: 0;
  text-align: center;
}

.create-resume-main .form-main .profile-img h5:first-child {
  border: 1px solid #ffa303;
}

.create-resume-main .form-main .profile-img h5:nth-child(2) {
  border: 1px solid #ba1a1a;
}

.create-resume-main .form-main .profile-img h5:last-child {
  border: 1px solid #521986;
}

.create-resume-main .form-main .form-control {
  height: 50px;
  box-shadow: none;
  border: none;
  color: black;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.create-resume-main-job .form-main .form-control {
  height: 50px;
  box-shadow: none;
  border: none;
  color: #521986;
  font-style: normal;
  font-weight: 400;
}

.create-resume-main .form-main .form-floating > label {
  color: #521986;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

select label {
  color: black;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.create-resume-main-job .form-main .form-floating > label {
  color: #ffa200;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.create-resume-main .form-main .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
}

/* .create-resume-main .form-main .text-Area-float > label {
  left: 0% !important;
} */
.create-resume-main .form-main .checkboxs > label {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1c1b1f;
  margin-right: 7px;
}

.create-resume-main-job .form-main .checkboxs > label {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1c1b1f;
  margin-right: 7px;
}

.create-resume-main .form-main .checkboxs > input[type="checkbox"] {
  accent-color: #521986;
  display: inline-flex;
  vertical-align: middle;
}

.save-experience {
  width: 100%;
  height: 39px;
  background: #521986;
  border: 0.5px solid #ffa303;
  border-radius: 10px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 10px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  margin: 1em 0;
}

.create-resume-main .sub-navbar-button {
  background-color: #f7f3ff;
  color: #521986;
  font-weight: 600;
  padding: 10px 15px;
  margin: 0 15px;
  border: none;
  display: flex;
  align-items: center;
}

.create-resume-main .sub-navbar-main {
  border-radius: 12px;
  padding: 20px;
  border-style: solid;
  border-color: #0000000d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
  position: relative;
}

.create-resume-main .sub-navbar-main .sub-navbar-options {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
}

.create-resume-main .sub-navbar-main .sub-navbar-toggleMenu {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  top: 80px;
  background-color: rgb(255, 255, 255);
  left: 0;
}

.create-resume-main .sub-navbar-button:hover {
  background-color: #521986;
  color: #f7f3ff;
}

.create-resume-main .sub-navbar-dropdownButton {
  background-color: #f7f3ff;
  color: #521986;
  font-weight: 600;
  border: none;
  padding: 10px 15px;
  margin: 0 15px;
}

.create-resume-main .sub-navbar-dropdownButton:hover {
  background-color: #521986;
  color: #f7f3ff;
}

.create-resume-main .sub-navbar-main .color-tray {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 18px auto;
  margin-bottom: 10px;
  padding: 15px 10px;
  border-radius: 7px;
  border-style: solid;
  border-color: rgba(255, 175, 25, 0.885);
  border-width: 1px;
  width: 100%;
  height: 80px;
}

.create-resume-main .sub-navbar-main .color-tray span {
  border-radius: 7px;
  height: 32px;
  width: 55px;
  margin: 8px 10px;
  background-color: #943ee3;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.289);
  border-width: 0.5px;
}

.create-resume-main .adjustment-tray {
  display: flex;
  flex-direction: row;
  margin: 18px auto;
  padding: 15px 10px;
  border-radius: 7px;
  border-style: solid;
  border-color: rgba(255, 175, 25, 0.885);
  border-width: 1px;
  width: 100%;
  height: 80px;
}

.create-resume-main .adjustment-tray span {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.create-resume-main .adjustment-tray-toggler {
  display: flex;
  justify-content: center;
}

.create-resume-main .adjustment-tray-meter {
  padding-top: 7px;
  display: flex;
  justify-content: center;
}

.create-resume-main .adjustment-tray-toggler img {
  width: 50px;
}

.create-resume-main .adjustment-tray-meter img {
  width: 100px;
}

.create-resume-main .adjustment-tray p {
  font-size: 10px;
  font-weight: 600;
  color: #521986;
  margin-bottom: 5px;
  text-align: center;
}

.create-resume-main .analytics-card-container {
  width: 30%;
  margin: 0 20px;
}

.analytics-card {
  margin: 10px auto;
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  border-style: solid;
  background: white;
  border-color: #0000000d;
  border-width: 2px;
}

.analytics-card p {
  font-size: 0.8rem;
  margin-top: 10px;
}

.create-resume-main .analytics-card .image-container {
  margin: 10px auto;
  display: flex;
  justify-content: center;
}

.create-resume-main .analytics-card .image-container img {
  height: 70px;
  width: 80px;
}

.create-resume-main .analytics-card p {
  margin: 10px auto;
  font-weight: 600;
  text-align: center;
  font-size: 13px;
}

.create-resume-main .rank-sidebar {
  padding: 0 8px;
  margin: 0 auto;
  width: 100px;
  height: 700px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 50px;
  border-style: solid;
  border-color: #0000000d;
}

.create-resume-main .rank-sidebar .image-container {
  display: flex;
  justify-content: center;
}

.create-resume-main .rank-sidebar p {
  text-align: center;
  margin: 5px 0;
  font-size: 12px;
  font-weight: 600;
}

.preview-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.preview-header .preview-header-name {
  font-size: 15px;
  color: #521986;
  background-color: transparent;
  padding: 5px;
  font-weight: 700;
}

.preview-header .preview-header-options {
  background-color: transparent;
  color: #521986;
  font-weight: 700;
  font-size: 15px;
  padding: 5px;
}

.sub-content-main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
}

.sub-content-main .col-lg-4 {
  width: 60%;
}

.template-container {
  border-radius: 15px;
  margin: 0 20px;
  width: 25%;
  border-style: solid;
  border-color: #0000000d;
}

.template-container .template-header {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}

.template-container .template-body-container {
  height: 1025px;
  overflow: scroll;
  overflow-x: hidden;
}

.template-body-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.template-body-container::-webkit-scrollbar-thumb {
  background-color: #ffba60;
}

.template-body-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.template-container .template-scrollbar-container img {
  width: 15px;
  margin-top: 25px;
}

.template-container .template-header span {
  font-weight: 700;
  font-size: 18px;
  color: #521986;
}

.template-container .template-image-container {
  border-radius: 12px;
  border-width: 0.6px;
  border-style: solid;
  border-color: #00000067;
  margin: 20px 10px;
  overflow: hidden;
  height: 400px;
  width: 245px;
}

.template-container .template-image-container img {
  width: 100%;
}

.template-container .template-image-container .template-image-info {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
}

.template-container .template-image-container .template-image-info span {
  font-size: 13px;
  font-weight: 700;
}

.template-container .template-image-container .template-image-info label {
  font-size: 10px;
  font-weight: 600;
  padding: 3px 8px;
  border-radius: 10px;
  background-color: #ffb960;
}

.template-container .template-image-container img:hover {
  cursor: pointer;
}

.template-preview-main {
  background-color: #cab7f8;
  width: 65%;
  padding: 15px;
  border-radius: 15px;
}

.hobby-addmore-button {
  font-weight: 600;
  font-size: 14px;
  margin: 10px 5px;
}

.hobby-addmore-button span {
  font-weight: 600;
  font-size: 20px;
}

.analytics-suggestion-container {
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  border-width: 0.6px;
  border-style: solid;
  border-color: #47474725;
}

.analytics-suggestion-container p {
  font-size: 18px;
  font-weight: 600;
  margin: 10px auto;
  margin-bottom: 20px;
}

.analytics-suggestion {
  border-radius: 5px;
  padding: 10px 8px;
  border-width: 0.6px;
  margin: 15px auto;
  box-shadow: 0px 0px 3px 3px rgba(117, 117, 117, 0.082);
}

.analytics-suggestion span {
  font-size: 12px;
  margin-left: 10px;
  font-weight: 600;
}

.analytics-suggestion span label {
  font-size: 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.413);
}

.redDot {
  display: inline-block;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: red;
}

.correction-heading {
  background-color: #933ee33a;
  color: #521986;
  font-size: 9px;
  padding: 3px 5px;
  border-radius: 4px;
}

.correction-container {
  margin: 10px 20px;
}

.correction-from {
  font-size: 8px;
  color: rgb(254, 68, 68);
  text-decoration: dashed;
}

.correction-to {
  font-size: 8px;
  background-color: #521986;
  color: white;
  padding: 3px 7px;
  border-radius: 3px;
  font-weight: 400;
}

.suggestion-error {
  margin: 10px 20px;
  font-size: px;
  color: rgba(255, 0, 0, 0.252);
}

.preview-modal-container {
  background-color: rgba(146, 146, 146, 0.794);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
}

.preview-modal-container img {
  height: 94%;
  margin: 20px auto;
  border-radius: 7px;
}

.preview-modal-image-container {
  position: relative;
}

.preview-modal-image-closeButton {
  position: absolute;
  right: -30px;
  top: 18px;
  background-color: transparent;
  border: none;
  font-size: 23px;
  font-weight: 600;
}

.preview-next-button {
  position: absolute;
  right: -100px;
  bottom: 37px;
  background-color: #521986;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 7px;
  font-size: 18px;
  font-weight: 600;
}

.finish-download-dropdown-container {
  position: relative;
}

.finish-download-dropdown-container button {
  position: relative;
  border-radius: 10px;
}

.finish-download-dropdownMenu-container {
  position: absolute;
  top: 50px;
  left: 18px;
  width: 157px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #ffba60;
}

.finish-download-dropdownMenu-container p {
  font-size: 12px;
  font-weight: 600;
  background-color: rgba(177, 118, 254, 0.095);
  padding: 8px 8px;
}

.add-skils-container {
  background-color: white;
  padding: 25px 0;
  border-radius: 10px;
  margin: 20px auto;
}

.add-skils-heading {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ffa735;
  padding: 0 10px;
  padding-bottom: 10px;
}

.add-skils-heading p {
  color: #ffa735;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  top: 10px;
}

/* experience */

.add-experience-container {
  background-color: white;
  padding: 10px 0;
  border-radius: 10px;
  margin: 20px auto;
}

.add-experience-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  padding-bottom: 10px;
}

.add-experience-heading p {
  color: #521986;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  top: 10px;
}

.add-skils-skillContainer {
  display: grid;
  padding: 0 10px;
  gap: 20px;
  align-items: center;
  display: flex;
  margin: 15px auto;
  flex-wrap: wrap;
}

.add-skils-skillContainer span {
  position: relative;
  min-width: 150px;
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.322);
  border-radius: 8px;
  background-color: white;
  padding: 8px 10px;
  color: #521986;
  font-weight: 600;
  transition: 0.2s all;
}

.add-skils-skillContainer span:hover {
  cursor: pointer;
  background-color: #b69df8;
  color: white;
  border: none;
}

.listPreview-container {
  margin: 20px 0;
  background-color: white;
  border-radius: 10px;
  padding: 10px 0;
  padding-right: 5px;
  height: 230px;
}

.listPreview-container p {
  font-size: 20px;
  font-weight: 700;
  padding: 10px 20px;
  padding-bottom: 25px;
  color: #521986;
  border-bottom: 1.5px solid #ffa126;
  margin-bottom: 0;
}

.form-main input::placeholder {
  color: black;
}

.skill-list-container {
  padding: 10px;
  background-color: white;
  margin-bottom: 16px;
  position: relative;
  width: 387px;
  border-radius: 7px;
  margin-left: 5px;
}

.skill-list-main {
  overflow: hidden;
}

.skill-list-wrapper {
  width: 350px;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  margin-left: 5px;
}

.skill-list-wrapper::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.skill-list-each {
  font-size: 12px;
  padding: 5px 8px;
  margin: 0 8px;

  box-shadow: 0px 0px 2px 2px rgba(117, 117, 117, 0.071);
  border-radius: 3px;
}

.skill-list-leftArrow img {
  width: 10px;
  height: 13px;
  cursor: pointer;
}

.skill-list-leftArrow {
  position: absolute;
  background-color: rgb(255, 255, 255);
  height: 24px;
  width: 24px;
  left: -10px;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 2px rgba(117, 117, 117, 0.176);
  color: #521986;
  font-weight: 700;
  font-size: 16px;
  z-index: 10;
}

.skill-list-rightArrow img {
  width: 10px;
  cursor: pointer;
  height: 13px;
}

.skill-list-rightArrow {
  position: absolute;
  background-color: rgb(255, 255, 255);
  height: 24px;
  width: 24px;
  right: -10px;
  top: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 2px rgba(117, 117, 117, 0.176);
  color: #521986;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.sidebar-Pointsdropdown-container {
  text-align: center;
  position: relative;
  width: 85%;
  margin: 0 auto;
}

.sidebar-Pointsdropdown-container button {
  display: inline-block;
  border-radius: 10px 10px 0 0;
  background-color: white;
  border: none;
  color: #521986;
  font-weight: 700;
  width: 100%;
  padding: 8px;
  font-size: 14px;
}

.sidebar-Pointsdropdown-container button img {
  height: 22px;
  width: 23px;
  margin-right: 5px;
}

.pointsDropdown-optionImage {
  height: 15px;
  width: 15px;
  position: relative;
  bottom: 1px;
  right: 3px;
}

.sub-navbar-dropdownMenu-container {
  position: absolute;
  width: 100%;
  background-color: white;
  padding: 5px 8px;
  border-radius: 0 0 10px 10px;
}

.sub-navbar-dropdownMenu-container h5 {
  font-size: 12px;
  color: #521986;
  text-align: left;
  font-weight: 700;
  margin: 4px 0;
  margin-bottom: 12px;
}

.sub-navbar-dropdownMenu-container p {
  text-align: left;
  color: #6b29a9d0;
  font-size: 11px;
  font-weight: 600;
  margin: 8px 0;
}

.py-2 {
  margin: 3px 0 !important;
}

.promotion-modal .modal-content .modal-body {
  padding: 0;
}

.promotion-modal .modal-content .modal-body img {
  border-radius: 10px;
}

.promotion-modal .modal-content {
  border-radius: 10px;
  background-color: #521986 !important;
}

.promotion-modal .modal-content .modal-footer {
  justify-content: space-around;
  background-color: #ffffff !important;
}

.promotion-modal .modal-content .modal-footer button:nth-child(1) {
  background-color: #521986 !important;
  color: #fff;
  border: none;
}

.promotion-modal .modal-content .modal-footer button:nth-child(2) {
  background-color: #ffffff !important;
  color: #521986;
  border: none;
  border: 1px solid #521986;
}

.skills-dropdownMenu-container {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: #b69df8;
  padding: 8px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.difficulty-container {
}

.difficulty-container h5 {
  background-color: #521986;
  font-size: 10px;
  padding: 8px;
  color: white;
  padding-left: 10px;
  border-radius: 7px;
  margin-bottom: 0;
}

.difficulty-dropdownMenu-container {
  border-radius: 7px;
  overflow: hidden;
}

.difficulty-dropdownMenu-container p {
  background-color: white;
  color: #521986;
  margin: 0;
  padding: 5px 7px;
  font-size: 10px;
  font-weight: 600;
  padding-left: 10px;
}

.difficulty-dropdownMenu-container p:hover {
  background-color: #e9ddff;
}

.difficulty-add-button {
  margin: 10px 0;
  background-color: #521986;
  display: inline-block;
  width: 100%;
  color: white;
  border-radius: 15px;
  text-align: center;
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
}

.difficulty-add-button label {
  font-size: 10px;
}

.highlight-tabs-container {
  display: flex;
  margin: 20px 0;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #ffa126;
  justify-content: space-between;
  background-color: #faefff;
}

.highlight-tabs-container span {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
  background-color: white;
  color: #521986;
}

.highlight-tabs-container span:hover {
  cursor: pointer;
  background-color: #521986;
  color: white;
}

.template_modal_head {
  color: #521986;
  font-weight: 700;
}

.modal_Scroll::-webkit-scrollbar-thumb {
  background: #ffa126;
  border-radius: 10px;
  margin-right: 10px;
}

.modal_Scroll {
  padding: 10px;
}

/* width */
.modal_Scroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
.modal_Scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* experience modal */
.experience_modal_containe h6 {
  font-size: 24px;
}

.experience_modal {
  background-color: #521986;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  align-items: center;
}

.ok {
}

.experience_modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.experience_modal_container img {
  height: 100px;
  width: 100px;
}

.experience_modal_feature_contatiner div {
  display: flex;
  width: 46%;
  margin: 8px;
  justify-content: center;
  align-items: center;
}

.experience_modal_feature_contatiner div p {
  margin-bottom: 0;
}

.experience_modal_feature_contatiner div span {
  background: #764a9f;
  padding: 5px;
  border-radius: 7px;
  margin-right: 10px;
}

.btn_color {
  background: #521986 !important;
  border: 2px solid #521986 !important;
}

.btn_continue {
  border: 2px solid #521986 !important;
  color: #521986 !important;
  font-weight: 600 !important;
}

.skillAdd button {
  background: #521986;
  color: white;
  /* align-self: center; */
  border-radius: 12px;
  padding: 10px 20px;
}

.Job-specific-label label {
  color: grey !important;
}

.template-cards {
  width: 300px;
}

.card-create {
  width: 300px;
  min-height: 400px;
}

.card-create-upper {
  height: 88%;
}

/* .Job-specific-label ::placeholder {
  color: grey !important;
} */
.dashboard-template-common-heading {
  margin-bottom: 0;
}

.textfield-lock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  color: white;
  /* background-color: #521986; */
  cursor: pointer;
  transition: 0s;
}

.textfield-lock-disabled {
  background: grey;
}

.textfield-lock-active {
  background-color: #521986;
}

.template-selector {
  object-fit: contain;
  height: 500px;
  overflow: auto;
  border: 0.5px solid #ebebeb;
}

.suggestion-box {
  height: 25vh;
  overflow: auto;
  margin-top: 5px;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.suggestion-box ul {
  list-style: none;
  padding-left: 0;
}

.suggestion-box ul li {
  border-bottom: 0.5px solid lightgray;
  padding: 8px 0;
  cursor: pointer;
}

.page-end-border-low {
  border-bottom: 2px dashed indigo;
  position: absolute;
  top: 21.8cm;
  width: 125%;
  z-index: 1;
  left: -30px;
}

.page-end-border-mid {
  border-bottom: 2px dashed orange;
  position: absolute;
  top: 47.5cm;
  width: 125%;
  z-index: 1;
  left: -30px;
}

.page-end-border-high {
  border-bottom: 2px dashed red;
  position: absolute;
  top: 72.9cm;
  width: 125%;
  z-index: 1;
  left: -30px;
}

.info-icon svg {
  position: absolute;
  right: 5px;
  top: 5px;
}

.optimization-modal h5 {
  color: #521986;
  font-weight: 700;
}

.optimization-modal p {
  margin-bottom: 8px;
  padding-left: 10px;
}

.optimization-modal p svg {
  color: #521986 !important;
  font-size: 15px;
  border: 1px solid #521986;
  padding: 3px;
  margin-right: 5px;
}

.resume-final {
  /* width: 21cm; */
  /* min-height: 29.7cm; */
}

.resume-sub-btn {
  padding: 1rem;
  margin-right: 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
}

.resume-sub-btn {
  white-space: nowrap;
}

.resume-sub-btn-inactive {
  background-color: #ebebeb;
  color: #521986;
}

.resume-sub-btn-active {
  background-color: #521986;
  color: white;
}

.improvise-summary-btn {
  background-color: #521986;
  color: white;
}

@media (max-width: 768px) {
  .step-progress-main {
    display: none;
  }

  .resume-sub-btn {
    width: 250px;
  }

  .create-resume-main .analytics-card-container {
    width: 100%;
    margin: 0;
    margin-top: 20px;
  }

  .create-resume-main .form-main {
    width: 100%;
  }

  .create-resume-main .form-main .form-floating > label {
    font-size: 16px;
  }

  .add-experience-heading p {
    font-size: 16px;
  }
}

.addpadding {
  position: relative;
  width: 100%;
  top: 21cm;
  border: 1px solid black;
  padding: 20px auto;
  /* top */
}

.required-label::after {
  content: "*";
  color: red;
  margin-left: 0.2em;
  /* Add spacing between label and asterisk if needed */
}
